<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="0"
    :return-value.sync="innerValue"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="$attrs"
        :style="styles"
        v-model="innerValue"
        :label="label"
        :placeholder="placeholder"
        :loading="loading"
        :disabled="disabled"
        readonly
        :color="color"
        :clearable="clearable"
        :error-messages="errorMessages"
        v-on="on"
        @click:clear="innerValue = null"
      />
    </template>
    <v-time-picker
      v-if="menu"
      :format="format"
      :max="max"
      :min="min"
      v-model="innerValue"
      @click:minute="$refs.menu.save(innerValue)"
      color="primary"
      :no-title="noTitle"
      scrollable
    />
  </v-menu>
</template>

<script>
  /**
   * @entity index
   * @author ali.sireli@meyer.com.tr
   * @description
   * @date 11.12.2019
   * @time 10:02
   */

  export default {
    inheritAttrs: false,
    name: "TimePicker",
    props: {
      value: {
        required: true,
        validator: prop => typeof prop === "string" || !prop
      },
      label: {
        type: String,
        required: false
      },
      placeholder: {
        type: String,
        required: false
      },
      icon: {
        type: String,
        required: false,
        default: null
      },
      color: {
        type: String,
        required: false,
        default: "tertiary"
      },
      errorMessages: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      noTitle: {
        type: Boolean,
        required: false,
        default: false
      },
      styles: {
        type: [String, Object],
        required: false,
        default: null
      },
      clearable: {
        type: Boolean,
        required: false,
        default: false
      },
      max: {
        type: String,
        required: false
      },
      min: {
        type: String,
        required: false
      },
      format: {
        validator: function(prop) {
          const options = ["ampm", "24hr"];
          return options.includes(prop);
        },
        required: false,
        default: "24hr"
      }
    },
    data: () => ({
      menu: false
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    }
  };
</script>

<style scoped></style>
